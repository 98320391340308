<template>
  <div>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-xxl-3 col-lg-3">
            <pre>balance: {{ balance }}</pre>
          </div>
          <div class="col-xl-3 col-xxl-3 col-lg-3">
            <pre>rate: {{ rate }}</pre>
          </div>
          <div class="col-xl-3 col-xxl-3 col-lg-3">
            <pre>accounts: {{ accounts }}</pre>
          </div>
          <div class="col-xl-3 col-xxl-3 col-lg-3">
            <pre>wallets: {{ wallets }}</pre>
          </div>
          <div class="col-xl-6 col-xxl-6 col-lg-6">
            <pre>deposits: {{ deposits }}</pre>
          </div>
          <div class="col-xl-6 col-xxl-6 col-lg-6">
            <pre>deposit details: {{ depositDetails }}</pre>
          </div>
          <div class="col-xl-6 col-xxl-6 col-lg-6">
            <pre>trades: {{ trades }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'Home',
  data: () => ({
    balance: 0,
    rate: null,
    accounts: [],
    depositDetails: {},
    deposits: [],
    trades: [],
    wallets: []
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.balance = await api.balance();
      this.rate = await api.rate();
      this.accounts = await api.account.read();
      this.depositDetails = await api.deposit.details();
      let account
      if (this.accounts && this.accounts.length > 0) {
        account = this.accounts[0]
      } else {
        account = await api.account.create({
          description: "testing account 1",
          account_number: "456" + parseInt(Math.random() * 10000000),
          routing_number: "123" + parseInt(Math.random() * 10000000)
        });
        this.accounts = await api.account.read();
      }
      
      this.wallets = await api.wallet.read();
      let wallet
      if (this.wallets && this.wallets.length > 0) {
        wallet = this.wallets[0]
      } else {
        wallet = await api.wallet.create({
          description: "testing wallet 1",
          address: "1btcFOOBARNOTAREALADDRESS" + parseInt(Math.random() * 10000000)
        });
        this.wallets = await api.wallet.read();
      }
      
      this.deposits = await api.deposit.read();
      if (!this.deposits || (this.deposits.every(d => !!d.verified_at) && this.balance <= 1000)) {
        await api.deposit.create({
          account_id: account.id,
          amount_fiat: 5000,
        });
        this.deposits = await api.deposit.read();
      }
      
      if (this.deposits.length > 0) {
        api.deposit.read(this.deposits[0].id)
      }
        
      
      if (this.balance >= 1000) {
        await api.trade.create({
          wallet_id: wallet.id,
          amount_fiat: 1000
        });
        this.balance = await api.balance();
      }
      this.trades = await api.trade.read();
    }
  }
};
</script>

<style lang="scss" scoped>
pre {
    color: #dcdccc;
}
</style>
