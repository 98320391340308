import Client from 'lib-oauth2-client-js'

let oauth = {}

if (window.oauthClientCredentials) {
  console.log('got client credentials')
  oauth = window.oauthClientCredentials
}

const baseURL = '/api/v1'

const client = new Client({
  axios: { baseURL },
  oauth
})

export default client

export function currentUserPhone () {
  if (!client.token) return ''
  return client.token.id.username
}

export function currentUserID () {
  if (!client.token) return 0
  return client.token.id.userID
}
