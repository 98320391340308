import api from './client'

export default {
  async create ({ amount_fiat, wallet_id }) {
    const resp = await api.post('/trade', {
      amount_fiat,
      wallet_id,
      currency_fiat: 'USD',
      currency_crypto: 'BTC'
    })
    return resp.data
  },
  async read(id) {
    let resp
    if (id) {
      resp = await api.get(`/trade/${id}`)  
    } else {
      resp = await api.get('/trade')  
    }
    return resp.data
  },
  async delete(id) {
    return api.delete(`trade/${id}`)
  }
}
