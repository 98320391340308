import Vue from 'vue';
import Cryptoicon from 'vue-cryptoicon';
import VueToast from 'vue-toast-notification';
import * as VueGoogleMaps from 'vue2-google-maps';
import VuePaginate from 'vue-paginate';
import icon from 'vue-cryptoicon/src/icons';
import 'vue-toast-notification/dist/theme-default.css';
import App from './App.vue';
import Vuelidate from 'vuelidate'
import router from './router';
import store from './store';
import './assets/scss/global.scss';
 
Vue.use(VueToast);
Vue.use(VuePaginate);
Vue.use(Vuelidate);

Cryptoicon.add(icon);
Vue.use(Cryptoicon);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP,
    libraries: 'places',
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
