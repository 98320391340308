import api from './client'

export default {
  async create ({ description, address }) {
    const resp = await api.post('/wallet', {
      description,
      address,
      currency_crypto: 'BTC'
    })
    return resp.data
  },
  async read(id) {
    let resp
    if (id) {
      resp = await api.get(`/wallet/${id}`)  
    } else {
      resp = await api.get('/wallet')  
    }
    return resp.data
  },
  async delete(id) {
    return api.delete(`wallet/${id}`)
  }
}
