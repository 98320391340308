<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    // Check localStorage to get theme
    const theme = localStorage.getItem('gc-theme');
    if (theme) {
      if (theme === 'light') {
        document.documentElement.className = 'theme--light';
      } else {
        document.documentElement.className = null;
      }
    } else {
      document.documentElement.className = 'theme--light';
    }
  },
};
</script>
