import api from './client'

export default {
  async create ({ description, account_number, routing_number }) {
    const resp = await api.post('/account', {
      description,
      account_number,
      routing_number
    })
    return resp.data
  },
  async read(id) {
    let resp
    if (id) {
      resp = await api.get(`/account/${id}`)  
    } else {
      resp = await api.get('/account')  
    }
    return resp.data
  },
  async delete(id) {
    return api.delete(`account/${id}`)
  }
}
