import api from './client'
import account from './account'
import deposit from './deposit'
import trade from './trade'
import wallet from './wallet'
import user from './user'

export default {
  async balance () {
    const resp = await api.get('/balance')
    return resp.data
  },
  async rate (params = { currency_crypto :'BTC', currency_fiat: 'USD' }) {
    const resp = await api.get('/rate', {
      params
    })
    return resp.data
  },
  account,
  deposit,
  trade,
  wallet,
  user
}
