import Home from '../views/Home.vue';

const routes = [
  {
    path: '/test-screen',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth-callback',
    name: 'AuthCallback',
    component: () => import(/* webpackChunkName: "AuthCallback" */ '../views/AuthCallback.vue'),
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Users" */ '../layout/Dashboard.vue'),
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */ '../views/Profile.vue'),
      },
      {
        path: 'trade',
        name: 'Trade',
        component: () => import(/* webpackChunkName: "Trade" */ '../views/Trade.vue'),
      },
      {
        path: 'transaction-history',
        name: 'History',
        component: () => import(/* webpackChunkName: "History" */ '../views/TransactionHistory.vue'),
      },
      {
        path: 'transaction/trade/:id',
        name: 'TransactionTrade',
        component: () => import(/* webpackChunkName: "History" */ '../views/TransactionTrade.vue'),
      },
      {
        path: 'transaction/deposit/:id',
        name: 'TransactionDeposit',
        component: () => import(/* webpackChunkName: "History" */ '../views/TransactionDeposit.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "Settings" */ '../views/ProfileSettings.vue'),
      },
      {
        path: 'settings-security',
        name: 'SettingsSecurity',
        component: () => import(/* webpackChunkName: "SettingsSecurity" */ '../views/settings/SettingsSecurity.vue'),
      },
      {
        path: 'settings-account',
        name: 'SettingsAccount',
        component: () => import(/* webpackChunkName: "SettingsAccount" */ '../views/settings/SettingsAccount.vue'),
      },
      {
        path: 'settings-crypto',
        name: 'SettingsCrypto',
        component: () => import(/* webpackChunkName: "SettingsCrypto" */ '../views/settings/SettingsCrypto.vue'),
      },
      {
        path: 'add-crypto-address',
        name: 'AddCryptoAddress',
        component: () => import(/* webpackChunkName: "AddCryptoAddress" */ '../views/settings/AddCryptoAddress.vue'),
      },
      {
        path: 'verify-step-2',
        name: 'VerifyStepTwo',
        component: () => import(/* webpackChunkName: "VerifyStepTwo" */ '../views/verification/StepTwo.vue'),
      },
      {
        path: 'verify-step-5',
        name: 'VerifyStepFive',
        component: () => import(/* webpackChunkName: "VerifyStepFive" */ '../views/verification/StepFive.vue'),
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import(/* webpackChunkName: "Verify" */ '../views/verification/ShowTerms.vue'),
      },
      {
        path: 'add-bank-account',
        name: 'AddBankAccount',
        component: () => import(/* webpackChunkName: "AddBankAccount" */ '../views/verification/AddBankAccount.vue'),
      },
      {
        path: 'deposit',
        name: 'Deposit',
        component: () => import(/* webpackChunkName: "MakeDeposit" */ '../views/MakeDeposit.vue'),
      },
      {
        path: 'bank-details',
        name: 'AccountDetails',
        component: () => import(/* webpackChunkName: "AccountDetails" */ '../views/AccountDetails.vue'),
      },
    ],
  },
];

export default routes;
