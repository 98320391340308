import api from './client'

export default {
  async details () {
    const resp = await api.get(`/deposit/details`)
    return resp.data
  },
  async create ({ account_id, amount_fiat }) {
    const resp = await api.post('/deposit', {
      account_id,
      amount_fiat,
      currency_fiat: 'USD'
    })
    return resp.data
  },
  async read(id) {
    let resp
    if (id) {
      resp = await api.get(`/deposit/${id}`)  
    } else {
      resp = await api.get('/deposit')  
    }
    return resp.data
  },
  async delete(id) {
    return api.delete(`deposit/${id}`)
  }
}
