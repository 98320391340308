import api from './client'

export default {
  async account () {
    const resp = await api.get(`/user`);
    return resp.data;
  },
  async getApprovedInfo() {
    const resp = await api.get(`/user/info`)
    return resp.data
  },
  async getPendingInfo() {
    const resp = await api.get(`/user/info`, {
      params: {
        status: "pending"
      }
    })
    return resp.data
  },
  async addInfo(data) {
    const resp = await api.post(`/user/info`, data)
    return resp.data
  }
};

